import { AuthService } from './../../../common/auth.service';
import { CommonService } from './../../../common/common.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    constructor(
        private commonService: CommonService,
        private authService: AuthService,
      ) {
        this.jwtHelper = new JwtHelperService();
        this.jwtHelper.tokenGetter = () => this.token;
      }
      path: string = environment.APP_HOME_PATH;
      private jwtHelper: JwtHelperService;
      private token?: string;
      user = {
        username: null,
        password: null,
        appId: null
      };
      inputType = 'password';
      showHideClass = 'fa fa-eye';

      showPassword() {
        if (this.user.password !== null) {
          if(this.inputType === 'password') {
            this.inputType = 'text';
            this.showHideClass = 'fa fa-eye-slash';
          } else {
            this.inputType = 'password';
            this.showHideClass = 'fa fa-eye';
          }
        }
      };

      ngOnInit() { }

      login() {
        this.user.appId = 222;
        this.authService.login(this.user, null).subscribe(() => { },
          error => {
            this.commonService.toastrDanger('Đăng nhập thất bại: ' + error);
          }
        );
      }

}
