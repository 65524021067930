import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxScrollTopModule } from 'ngx-scrolltop';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { SidebarComponent } from './components/layouts/sidebar/sidebar.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { ProjectDetailsComponent } from './components/pages/project-details/project-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { FeaturesComponent } from './components/pages/features/features.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { PartnerComponent } from './components/pages/partner/partner.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { HomeDemoOneComponent } from './components/pages/home-demo-one/home-demo-one.component';
import { HomeDemoTwoComponent } from './components/pages/home-demo-two/home-demo-two.component';
import { HomeDemoThreeComponent } from './components/pages/home-demo-three/home-demo-three.component';
import { HomeDemoFourComponent } from './components/pages/home-demo-four/home-demo-four.component';
import { HomeDemoFiveComponent } from './components/pages/home-demo-five/home-demo-five.component';
import { HomeDemoSixComponent } from './components/pages/home-demo-six/home-demo-six.component';
import { HomeDemoSevenComponent } from './components/pages/home-demo-seven/home-demo-seven.component';
import { HomeDemoEightComponent } from './components/pages/home-demo-eight/home-demo-eight.component';
import { HomeDemoNineComponent } from './components/pages/home-demo-nine/home-demo-nine.component';
import { AboutStyleTwoComponent } from './components/pages/about-style-two/about-style-two.component';
import { TeamStyleTwoComponent } from './components/pages/team-style-two/team-style-two.component';
import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { ServicesStyleTwoComponent } from './components/pages/services-style-two/services-style-two.component';
import { ServicesStyleThreeComponent } from './components/pages/services-style-three/services-style-three.component';
import { ProjectsStyleTwoComponent } from './components/pages/projects-style-two/projects-style-two.component';
import { ProjectsStyleThreeComponent } from './components/pages/projects-style-three/projects-style-three.component';
import { ProjectsStyleFourComponent } from './components/pages/projects-style-four/projects-style-four.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { HomeDemoTenComponent } from './components/pages/home-demo-ten/home-demo-ten.component';
import { HomeDemoElevenComponent } from './components/pages/home-demo-eleven/home-demo-eleven.component';
import { HomeDemoTwelveComponent } from './components/pages/home-demo-twelve/home-demo-twelve.component';
import { NavbarStyleTwoComponent } from './components/layouts/navbar-style-two/navbar-style-two.component';
import { NavbarStyleThreeComponent } from './components/layouts/navbar-style-three/navbar-style-three.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule, NbToastrModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_QTHT_URL } from './common/app-qtht.service';
import { environment } from 'src/environments/environment';
import { MatTabsModule } from '@angular/material/tabs';
import { CookieModule } from 'ngx-cookie';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { ChangePasswordComponent } from './components/pages/change-password/change-password.component';
import { DialogConfirmComponent } from './components/pages/dialog-confirm/dialog-confirm.component';
import { UserInfoComponent } from './components/pages/user-info/user-info.component';
import { BtnCloseComponent } from './components/pages/template/button/close/btn-close.component';
import { BtnConfirmComponent } from './components/pages/template/button/confirm/btn-confirm.component';
import { BtnSubmitComponent } from './components/pages/template/button/submit/btn-submit.component';
import { AuthInterceptor } from './common/interceptors/auth.interceptor';
@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    AboutComponent,
    ServicesComponent,
    ServiceDetailsComponent,
    ProjectsComponent,
    ProjectDetailsComponent,
    BlogComponent,
    BlogDetailsComponent,
    FeaturesComponent,
    TeamComponent,
    PricingComponent,
    PartnerComponent,
    NotFoundComponent,
    FaqComponent,
    ContactComponent,
    HomeDemoOneComponent,
    HomeDemoTwoComponent,
    HomeDemoThreeComponent,
    HomeDemoFourComponent,
    HomeDemoFiveComponent,
    HomeDemoSixComponent,
    HomeDemoSevenComponent,
    HomeDemoEightComponent,
    HomeDemoNineComponent,
    AboutStyleTwoComponent,
    TeamStyleTwoComponent,
    LoginComponent,
    RegisterComponent,
    ServicesStyleTwoComponent,
    ServicesStyleThreeComponent,
    ProjectsStyleTwoComponent,
    ProjectsStyleThreeComponent,
    ProjectsStyleFourComponent,
    BlogRightSidebarComponent,
    HomeDemoTenComponent,
    HomeDemoElevenComponent,
    HomeDemoTwelveComponent,
    NavbarStyleTwoComponent,
    NavbarStyleThreeComponent,
    UserInfoComponent,
    ChangePasswordComponent,
    DialogConfirmComponent,
    BtnCloseComponent,
    BtnConfirmComponent,
    BtnSubmitComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxScrollTopModule,
    NgSelectModule,
    NoopAnimationsModule,
    NbThemeModule.forRoot(),
    NbLayoutModule,
    NbEvaIconsModule,
    HttpClientModule,
    NbToastrModule.forRoot(),
    CookieModule.forRoot(),
    MatTabsModule,
    MatDialogModule,
    NgxSpinnerModule
  ],
  entryComponents: [DialogConfirmComponent],
  providers: [
    { provide: APP_QTHT_URL, useValue: environment.APP_QTHT_URL },
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
      }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
