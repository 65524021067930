<!-- Start Footer Area -->
<section class="footer-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>CƠ QUAN CHỦ QUẢN:<br /> SỞ THÔNG TIN VÀ TRUYỀN THÔNG THÀNH PHỐ HÀ NỘI</h3>
                    <ul class="footer-contact-info">
                        <li>
                            <i class="flaticon-phone-call"></i>
                            <span>Điện thoại</span>
                            <a href="tel:(024) 35123536">(024) 35123536</a>
                            <span>Fax</span>
                            <a href="tel:(025) 37366617">(025) 37366617</a>
                        </li>
                        <li>
                            <i class="flaticon-email"></i>
                            <span>Email</span>
                            <a href="mailto:tiepnhanykien_sotttt@hanoi.gov.vn">tiepnhanykien_sotttt@hanoi.gov.vn</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Vị trí của thành phố</h3>
                    <ul class="footer-quick-links">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25053.436077643957!2d105.81985792050213!3d21.02405942909083!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ab9bd9861ca1%3A0xe7887f7b72ca17a9!2zSMOgIE7hu5lpLCBIb8OgbiBLaeG6v20sIEjDoCBO4buZaSwgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1672473122369!5m2!1svi!2s"
                            width="650" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
        </div>
    </div>
</section>
<!-- End Footer Area -->