<!-- Start Navbar Area -->
<div class="navbar-area {{navbarClass}}">
    <div class="karlson-nav">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand" routerLink="/" style="display: contents;"><img src="{{path}}/assets/img/png/logoHanoi.svg.png" alt="logo" class="logo">&nbsp;
                    <p class="logotext">Kho dữ liệu chuyển đổi số và <br /> Cổng dữ liệu mở thành phố Hà Nội</p>
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle styleTimes" href="javascript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Lĩnh vực <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item styleTimes" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="/thongtin-tt" routerLinkActive="active" class="nav-link">Lĩnh vực Thông tin
                                        - Truyền thông</a>
                                </li>
                                <li class="nav-item styleTimes" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="/app-stp" routerLinkActive="active" class="nav-link">Lĩnh vực Tư pháp</a>
                                    <!-- [routerLinkActiveOptions]="{exact: true}" -->
                                </li>
                                <li class="nav-item styleTimes" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="/thanh-tra" routerLinkActive="active" class="nav-link">Lĩnh vực Thanh
                                        tra</a>
                                </li>
                                <li class="nav-item styleTimes" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="/app-gddt" routerLinkActive="active" class="nav-link">Lĩnh vực Giáo dục &
                                        Đào tạo</a>
                                </li>
                                <li class="nav-item styleTimes" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="/gtvt" routerLinkActive="active" class="nav-link">Lĩnh vực Giao thông - Vận
                                        tải</a>
                                </li>
                                <li class="nav-item styleTimes" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="/app-snv" routerLinkActive="active" class="nav-link">Lĩnh vực Nội vụ</a>
                                </li>
                                <li class="nav-item styleTimes" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="/app-khdt" routerLinkActive="active" class="nav-link">Lĩnh vực Kế hoạch -
                                        Đầu tư</a>
                                </li>
                                <li class="nav-item styleTimes" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="/vhtt" routerLinkActive="active" class="nav-link">Lĩnh vực Văn hóa - Thể
                                        thao</a>
                                </li>
                                <li class="nav-item styleTimes" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="/sodl" routerLinkActive="active" class="nav-link">Lĩnh vực Du lịch</a>
                                </li>
                                <li class="nav-item styleTimes" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="/stc" routerLinkActive="active" class="nav-link">Lĩnh vực Tài chính</a>
                                </li>
                                <li class="nav-item styleTimes" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="/app-dmdc" routerLinkActive="active" class="nav-link">Danh mục dùng
                                        chung</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                            <a href="https://dichvucong.hanoi.gov.vn/" routerLinkActive="active" class="nav-link">Dịch
                                vụ công trực tuyến</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Tiện ích <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/hdaddacc" routerLinkActive="active" class="nav-link">Hướng dẫn tạo
                                        tài khoản</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/hdsearch" routerLinkActive="active" class="nav-link">Hướng dẫn tìm
                                        kiếm dữ liệu</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/" routerLinkActive="active" class="nav-link">Hướng dẫn tạo dịch
                                        vụ</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="!user" routerLink="/login" routerLinkActive="active" class="nav-link">Đăng
                                nhập</a>
                            <a *ngIf="user" class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Xin chào: {{user.FullName}}
                                <i class="fas fa-chevron-down"></i>
                            </a>

                            <ul class="dropdown-menu" *ngIf="user">
                                <li class="nav-item">
                                    <a (click)="userInfo()" routerLinkActive="active" class="nav-link">Thông tin cá
                                        nhân</a>
                                </li>
                                <li class="nav-item">
                                    <a (click)="rePassword()" routerLinkActive="active" class="nav-link">Đổi mật
                                        khẩu</a>
                                </li>
                                <li class="nav-item">
                                    <a (click)="logout()" routerLinkActive="active" class="nav-link">Đăng xuất</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <div class="others-options">
                        <div class="burger-menu">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->