import { SUCCESS_RESPONSE } from './../../../common/contants';
import { CommonService } from 'src/app/common/common.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ChangePasswordService } from './change-password.service';
import { ChangePasswordModel } from 'src/app/common/app-qtht.service';

@Component({
  selector: 'ngx-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    private commonService: CommonService,
    private changePasswordService: ChangePasswordService
  ) { }

  target = {
    oldpassword: '',
    newpassword: '',
    newpasswordagain: ''
  };
  hideOrShowOldPass: boolean = false;
  hideOrShowNewPass: boolean = false;
  hideOrShowNewPassAgain: boolean = false;

  ngOnInit(): void { }

  changePassword() {
    const model = new ChangePasswordModel({
      newPassword: this.target.newpassword,
      oldPassword: this.target.oldpassword
    });
    this.changePasswordService.changePassword(model).subscribe(rs => {
      if (rs.code === SUCCESS_RESPONSE) {
        this.commonService.toastrSuccess();
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(rs.message);
      }
    });
  }
}
