<app-navbar></app-navbar>

<!-- Start Main Banner Area -->
<div class="seo-banner-slider owl-carousel owl-theme">
    <div class="seo-banner">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-50">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="banner-content">
                                <h1>Kho dữ liệu <br /> (Data warehouse)</h1>
                                <p>Data warehouse (DW) hay kho dữ liệu là một hệ thống lưu trữ dữ liệu từ nhiều nguồn, nhiều môi trường khác nhau.</p>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="seo-banner-image animate-tb">
                                <img class="pngitem" src="{{path}}/assets/img/png/Data-Warehouse.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Shape Images -->
        <div class="shape-img2"><img src="{{path}}/assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="{{path}}/assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="{{path}}/assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="{{path}}/assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="{{path}}/assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="{{path}}/assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="{{path}}/assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="{{path}}/assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="{{path}}/assets/img/shape/shape10.png" alt="image"></div>
        <div class="shape-img13"><img src="{{path}}/assets/img/shape/shape13.png" alt="image"></div>
        <div class="shape-img14"><img src="{{path}}/assets/img/shape/shape14.png" alt="image"></div>
    </div>

    <!-- <div class="seo-banner">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-50">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="banner-content">
                                <h1>Nền tảng bản đồ</h1>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="seo-banner-image animate-tb">
                                <img src="{{path}}/assets/img/png/map.png" alt="image" style="border-radius: 100px;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

    <!-- Shape Images -->
    <!-- <div class="shape-img2"><img src="{{path}}/assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="{{path}}/assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="{{path}}/assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="{{path}}/assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="{{path}}/assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="{{path}}/assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="{{path}}/assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="{{path}}/assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="{{path}}/assets/img/shape/shape10.png" alt="image"></div>
        <div class="shape-img13"><img src="{{path}}/assets/img/shape/shape13.png" alt="image"></div>
        <div class="shape-img14"><img src="{{path}}/assets/img/shape/shape14.png" alt="image"></div>
    </div> -->

    <div class="seo-banner">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-50">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="banner-content">
                                <h1>Chuyển đổi số <br />(Digital Transformation)</h1>
                                <p>Sử dụng công nghệ để thực hiện lại quy trình sao cho hiệu quả hơn hoặc hiệu quả hơn.
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="seo-banner-image animate-tb">
                                <img class="pngitem" src="{{path}}/assets/img/png/digital.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Shape Images -->
        <div class="shape-img2"><img src="{{path}}/assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="{{path}}/assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="{{path}}/assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="{{path}}/assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="{{path}}/assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="{{path}}/assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="{{path}}/assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="{{path}}/assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="{{path}}/assets/img/shape/shape10.png" alt="image"></div>
        <div class="shape-img13"><img src="{{path}}/assets/img/shape/shape13.png" alt="image"></div>
        <div class="shape-img14"><img src="{{path}}/assets/img/shape/shape14.png" alt="image"></div>
    </div>
</div>
<!-- End Main Banner Area -->

<!-- Start Services Area -->
<section class="services-area ptb-100 bg-F4F7FC">
    <div class="container">
        <div class="section-title">
            <h2>Dữ liệu theo lĩnh vực</h2>
        </div>

        <div class="row">

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/thongtin.svg" alt="image">
                    </div>
                    <h3><a href="/thongtin-tt/">Lĩnh vực Thông tin Truyền thông</a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Thông tin Truyền thông</p><br />
                    <p></p>
                    <a href="/thongtin-tt/" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/thanhtra.svg" alt="image">
                    </div>
                    <h3><a href="/thanh-tra/">Lĩnh vực Thanh tra</a></h3>
                    <p>Dữ liệu được cung cấp từ Thanh tra thành phố</p><br />
                    <p></p>
                    <a href="/thanh-tra/" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/giaothong.svg" alt="image">
                    </div>
                    <h3><a href="/gtvt/">Lĩnh vực Giao Thông, Vận tải</a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Giao thông, vận tải</p><br />
                    <p></p>
                    <a href="/gtvt/" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-00aeff">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/noivu.svg" alt="image">
                    </div>
                    <h3><a href="/app-snv/">Lĩnh vực Nội vụ</a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Nội vụ</p><br />
                    <p></p>
                    <a href="/app-snv/" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-f78acb">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/kehoachdautu.svg" alt="image">
                    </div>
                    <h3><a href="/khdt/">Lĩnh vực Kế hoạch và Đầu tư</a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Kế hoạch và Đầu tư</p><br />
                    <p></p>
                    <a href="/khdt/" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-cdf1d8">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/taichinh.svg" alt="image">
                    </div>
                    <h3><a href="/stc/">Lĩnh vực Tài chính</a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Tài chính</p><br />
                    <p></p>
                    <a href="/stc/" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-eb6b3d">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/vhtt.svg" alt="image">
                    </div>
                    <h3><a href="/vhtt/">Lĩnh vực Văn hóa và Thể thao</a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Văn hóa, Thể thao</p>
                    <p></p>
                    <a href="/vhtt/" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-eb6b3d">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/dulich.svg" alt="image">
                    </div>
                    <h3><a href="/sodl/">Lĩnh vực Du lịch</a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Du lịch</p>
                    <p></p>
                    <a href="/sodl/" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-eb6b3d">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/giaoduc.svg" alt="image">
                    </div>
                    <h3><a href="/app-gddt/">Lĩnh vực Giáo dục & Đào tạo</a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Giáo dục & Đào tạo</p><br />
                    <a href="/app-gddt/" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-00aeff">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/tuphap.svg" alt="image">
                    </div>
                    <h3><a href="/app-stp/">Lĩnh vực Tư pháp </a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Tư pháp</p><br />
                    <a href="/app-stp/" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-c679e3">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/danhmuc.svg" alt="image">
                    </div>
                    <h3><a href="/tnmt/">Danh mục dùng chung</a></h3>
                    <p>Dữ liệu được cung cấp từ NGSP</p>
                    <p></p>
                    <a href="/tnmt/" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Fun Facts Area -->
<section class="fun-facts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="324">00</span><span class="sign-icon">+</span></h3>
                    <p>Dữ liệu chia sẻ</p>
                    <div class="back-text"></div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="13">00</span></h3>
                    <p>Đơn vị chia sẻ</p>
                    <div class="back-text"></div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="48">00</span><span class="sign-icon">+</span></h3>
                    <p>Các liên kết</p>
                    <div class="back-text"></div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Fun Facts Area -->

<!-- Start Partner Area -->
<div class="partner-area ptb-100 pb-0">
    <div class="container">
        <div class="section-title">
            <h2>Liên kết cổng thông tin các đơn vị</h2>
        </div>

        <div class="partner-slides owl-carousel owl-theme">

            <div class="cs-partner-item">
                <a href="http://congthuong.hanoi.gov.vn/" target="_blank">
                    <img style="    width: 68% !important;" src="{{path}}/assets/img/png/quochuy.png" alt="image">
                    <p style="text-align: center;">Sở Công Thương</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="https://dost.hanoi.gov.vn/" target="_blank">
                    <img style="    width: 68% !important;" src="{{path}}/assets/img/png/quochuy.png" alt="image">
                    <p style="text-align: center;">Sở Khoa học và Công nghệ</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="https://dfa.hanoi.gov.vn/" target="_blank">
                    <img style="    width: 68% !important;" src="{{path}}/assets/img/png/quochuy.png" alt="image">
                    <p style="text-align: center;">Sở Ngoại vụ</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="https://sotttt.hanoi.gov.vn/" target="_blank">
                    <img class="imgPng" src="{{path}}/assets/img/png/stttt.jpg" alt="image">
                    <p style="text-align: center;">Sở Thông tin và Truyền thông</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="http://sotuphap.hanoi.gov.vn/" target="_blank">
                    <img class="imgPng" src="{{path}}/assets/img/png/stp.png" alt="image">
                    <p style="text-align: center;">Sở Tư pháp</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="http://sovhtt.hanoi.gov.vn/" target="_blank">
                    <img style="    width: 68% !important;" src="{{path}}/assets/img/png/quochuy.png" alt="image">
                    <p style="text-align: center;">Sở Văn hóa, Thể thao và Du lịch</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="https://www.soxaydung.hanoi.gov.vn/vi-vn" target="_blank">
                    <img style="    width: 68% !important;" src="{{path}}/assets/img/png/quochuy.png" alt="image">
                    <p style="text-align: center;">Sở Xây dựng</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="https://thanhtra.hanoi.gov.vn/" target="_blank">
                    <img class="imgPng" src="{{path}}/assets/img/png/ttr.png" alt="image">
                    <p style="text-align: center;">Thanh tra thành phố</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="https://sogtvt.hanoi.gov.vn/" target="_blank">
                    <img style="    width: 68% !important;" src="{{path}}/assets/img/png/quochuy.png" alt="image">
                    <p style="text-align: center;">Sở Giao thông Vận tải</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="https://sonoivu.hanoi.gov.vn/" target="_blank">
                    <img style="    width: 68% !important;" src="{{path}}/assets/img/png/quochuy.png" alt="image">
                    <p style="text-align: center;">Sở Nội vụ</p>
                </a>
            </div>
            <div class="cs-partner-item">
                <a href="http://www.hapi.gov.vn/" target="_blank">
                    <img style="    width: 68% !important;" src="{{path}}/assets/img/png/quochuy.png" alt="image">
                    <p style="text-align: center;">Sở Kế hoạch và Đầu tư</p>
                </a>
            </div>
            <div class="cs-partner-item">
                <a href="https://sotaichinh.hanoi.gov.vn/" target="_blank">
                    <img style="    width: 68% !important;" src="{{path}}/assets/img/png/quochuy.png" alt="image">
                    <p style="text-align: center;">Sở Tài Chính</p>
                </a>
            </div>
            <div class="cs-partner-item">
                <a href="https://solaodong.hanoi.gov.vn/" target="_blank">
                    <img style="    width: 68% !important;" src="{{path}}/assets/img/png/quochuy.png" alt="image">
                    <p style="text-align: center;">Sở Lao động - Thương binh và Xã hội</p>
                </a>
            </div>
            <div class="cs-partner-item">
                <a href="https://hanoi.edu.vn/" target="_blank">
                    <img style="    width: 68% !important;" src="{{path}}/assets/img/png/quochuy.png" alt="image">
                    <p style="text-align: center;">Sở Giáo dục và Đào tạo</p>
                </a>
            </div>
            <div class="cs-partner-item">
                <a href="https://soyte.hanoi.gov.vn/" target="_blank">
                    <img style="    width: 68% !important;" src="{{path}}/assets/img/png/quochuy.png" alt="image">
                    <p style="text-align: center;">Sở Y tế</p>
                </a>
            </div>
            <div class="cs-partner-item">
                <a href="http://sotnmt.hanoi.gov.vn/" target="_blank">
                    <img style="    width: 68% !important;" src="{{path}}/assets/img/png/quochuy.png" alt="image">
                    <p style="text-align: center;">Sở Tài nguyên, Môi trường</p>
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area pb-100">
    <div class="container">
    </div>
</section>
<!-- End Subscribe Area -->