export const SUCCESS_RESPONSE = 'Success';
export const DUPLICATE_RESPONE = 'Duplicate';
export const NOTFOUND_RESPONE = 'NotFound';
export const SUCCESS_NOTICE = 'Thành công';
export const TITLE_NOTICE = 'Thông báo';
export const PAGE_SIZE = 10;
export const listSex = [
    {
        value: 1,
        text: 'Nam'
    },
    {
        value: 2,
        text: 'Nữ'
    },
    {
        value: 3,
        text: 'Khác'
    }
];
export const LBL_DISPLAY = {
  syncNgsp: 'Đồng bộ NGSP',
  add: 'Thêm mới',
  edit: 'Sửa',
  update: 'Cập nhập',
  delete: 'Xóa',
  view: 'Xem',
  search: 'Tìm kiếm',
  save: 'Lưu',
  print: 'In',
  close: 'Đóng',
  back: 'Quay lại',
  refresh: 'Làm mới',
  file: 'Chọn file',
  confirm: 'Xác nhận',
  cancel: 'Hủy',
  importFile: 'Import',
  downloadTemplate: 'Tải template',
  sync: 'Đồng bộ',
};

export const WIDTH_DIALOG = '80%';
export const HEIGHT_DIALOG = '100%';
export const SEARCH_LABEL = 'Tìm kiếm';
export const REFRESH_LABEL = 'Làm mới';
export const ADDNEW_LABEL = 'Thêm mới';
export const EDIT_LABEL = 'Sửa';
export const DETAIL_LABEL = 'Xem';
export const DELETE_LABEL = 'Xóa';
