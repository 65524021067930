<form #frm="ngForm" (ngSubmit)="changePassword()">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
            <h3>Đổi mật khẩu</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
            <div class="form-group">
                <label class="col-form-label required">Mật khẩu cũ</label>
                <div class="input-group">
                    <input autocomplete="off" [type]="hideOrShowOldPass ? 'text' : 'password'" class="form-control" name="oldpassword" [(ngModel)]="target.oldpassword" maxlength="50" #oldpassword="ngModel" required>
                    <div class="input-group-append">
                        <span class="input-group-text" (click)="hideOrShowOldPass = !hideOrShowOldPass">
                                    <i class="fa" [ngClass]="{'fa-eye': hideOrShowOldPass, 'fa-eye-slash': !hideOrShowOldPass}" aria-hidden="true"></i>
                                </span>
                    </div>
                </div>
            </div>
            <div *ngIf="oldpassword.errors && (oldpassword.dirty || oldpassword.touched)" class="alert alert-danger">
                <div [hidden]="!oldpassword.errors.required">Mật khẩu cũ là bắt buộc</div>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
            <div class="form-group">
                <label class="col-form-label required">Mật khẩu mới</label>
                <div class="input-group">
                    <input autocomplete="off" [type]="hideOrShowNewPass ? 'text' : 'password'" class="form-control" name="newpassword" [(ngModel)]="target.newpassword" maxlength="50" #newpassword="ngModel" required>
                    <div class="input-group-append">
                        <span class="input-group-text" (click)="hideOrShowNewPass = !hideOrShowNewPass">
                                    <i class="fa" [ngClass]="{'fa-eye': hideOrShowNewPass, 'fa-eye-slash': !hideOrShowNewPass}" aria-hidden="true"></i>
                                </span>
                    </div>
                </div>
            </div>
            <div *ngIf="newpassword.errors && (newpassword.dirty || newpassword.touched)" class="alert alert-danger">
                <div [hidden]="!newpassword.errors.required">Mật khẩu mới là bắt buộc</div>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
            <div class="form-group">
                <label class="col-form-label required">Nhập lại mật khẩu mới</label>
                <div class="input-group">
                    <input autocomplete="off" [type]="hideOrShowNewPassAgain ? 'text' : 'password'" class="form-control" name="newpasswordagain" [(ngModel)]="target.newpasswordagain" maxlength="50" #newpasswordagain="ngModel" required>
                    <div class="input-group-append">
                        <span class="input-group-text" (click)="hideOrShowNewPassAgain = !hideOrShowNewPassAgain">
                                    <i class="fa" [ngClass]="{'fa-eye': hideOrShowNewPassAgain, 'fa-eye-slash': !hideOrShowNewPassAgain}" aria-hidden="true"></i>
                                </span>
                    </div>
                </div>
            </div>
            <div *ngIf="newpasswordagain.errors && (newpasswordagain.dirty || newpasswordagain.touched)" class="alert alert-danger">
                <div [hidden]="!newpasswordagain.errors.required">Nhập lại mật khẩu mới là bắt buộc</div>
            </div>
            <div *ngIf="target.newpasswordagain !== '' && target.newpassword !== target.newpasswordagain" class="alert alert-danger">
                <div>Nhập lại mật khẩu không trùng khớp</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-end">
            <ngx-btn-submit [disabled]="frm.invalid || target.newpassword !== target.newpasswordagain"></ngx-btn-submit>
            &nbsp;
            <ngx-btn-close mat-dialog-close></ngx-btn-close>
        </div>
    </div>
</form>