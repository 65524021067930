import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/common/common.service';
import { AuthService } from 'src/app/common/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { UserInfoComponent } from '../../pages/user-info/user-info.component';
import { DialogConfirmComponent } from '../../pages/dialog-confirm/dialog-confirm.component';
import { ChangePasswordComponent } from '../../pages/change-password/change-password.component';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class NavbarComponent implements OnInit {

    location: any;
    navbarClass: any;
    path: string = environment.APP_HOME_PATH;
    user = JSON.parse(localStorage.getItem('access_user'));
    constructor(
    private commonService: CommonService,
    private authService: AuthService,
    private dialog: MatDialog
    ) {
        this.navbarClass = 'navbar-area';
    }

    ngOnInit() {}

    userInfo() {
        const data = {
          userId: this.user.UserId
        };
        this.dialog.open(UserInfoComponent, this.commonService.configDialog('40%', { data: data }))
                .afterClosed().subscribe(() => {});
      }

    logout() {
      this.dialog
        .open(
          DialogConfirmComponent,
          this.commonService.configDialog('35%', {
            title: 'Xác nhận',
            message: 'Bạn có chắc chắn muốn đăng xuất ?',
            color: 'text-warning',
            type: 1,
            icon: 'exclamation-triangle',
            isAppend: true,
          })
        )
        .afterClosed()
        .subscribe((isOk) => {
          if (isOk) {
            this.user = null;
            localStorage.removeItem('access_user');
            this.authService.logout();
          }
        });
    }

    rePassword() {
      this.dialog.open(ChangePasswordComponent, this.commonService.configDialog('40%', {}))
                .afterClosed().subscribe(() => {});
    }

}
