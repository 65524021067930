<div class="row">
    <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-center">
        <h3 class="tittle-color">{{title}}</h3>
    </div>
</div>
<div class="row">
    <div class="col-1 midle-content" style="margin: auto;">
        <i style="font-size: 1.7rem; vertical-align: super !important;" class="fas fa-{{icon}} {{color}}"></i>
    </div>
    <div class="col b midle-content" style="cursor: pointer">
        <label [innerHtml]="message" *ngIf="isAppend" class="label-confirm"></label>
        <label *ngIf="!isAppend" class="label-confirm">{{message}}</label>
    </div>
</div>
<div class="row">
    <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-end">
        <ngx-btn-confirm [text]="'Đồng ý'" (click)="confirm(true);"></ngx-btn-confirm>&nbsp;
        <ngx-btn-close *ngIf="type === 1" [text]="'Hủy'" (click)="confirm(false);"></ngx-btn-close>
    </div>
</div>