import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppQthtApiService, AuthenticateRequest, TokenForRefresh } from './app-qtht.service';
import { CommonService } from './common.service';
import { TokenService } from './token.service';
import { SUCCESS_RESPONSE } from './contants';

export interface IUserDataToken {
  Id: number;
  FullName: string;
  UnitCode: string;
  UnitName: string;
  RefreshToken: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  redirectUrl = '';

  private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

  private jwtHelper: JwtHelperService;
  private token?: string;
  constructor (
    private http: HttpClient,
    private tokenService: TokenService,
    private commonService: CommonService,
    private appQthtService: AppQthtApiService,
    private router: Router) {
      this.jwtHelper = new JwtHelperService();
      this.jwtHelper.tokenGetter = () => this.token;
      this.loadSavedAuthData();
  }

  private static log(message: string): any {
    // console.log(message);
  }

  login(loginData: any, state: string): Observable<any> {
    this.tokenService.removeToken();
    // this.tokenService.removeRefreshToken();
    const body = new HttpParams()
      .set('username', loginData.username)
      .set('password', loginData.password)
      .set('appId', loginData.appId)
      .set('tag', null)
      .set('grant_type', 'password');

    const userLogin = new AuthenticateRequest({
      username: loginData.username,
      password: loginData.password,
      appId: loginData.appId,
      tag: null,

    });
    return this.appQthtService.appAuthApiAuthAuthenhome(userLogin)
    .pipe(
      tap(res => {
        if (res.data) {
          this.tokenService.saveToken(res.data.jwtToken);
          this.token = res.data.jwtToken;
          const jwtToken = JSON.stringify(this.token);
          var currentUser = this.jwtHelper.decodeToken(jwtToken);
          localStorage.setItem('access_user', JSON.stringify(currentUser));
          const usInfor: IUserDataToken = {
            Id: res.data.id,
            FullName: res.data.fullName,
            UnitCode: res.data.unitCode,
            UnitName: res.data.unitName,
            RefreshToken: res.data.refreshToken,
          };
          this.tokenService.setUserInfo(usInfor, this.jwtHelper.getTokenExpirationDate());
          this.router.navigate(['/']).then(() => {
            this.commonService.toastrSuccess('Đăng nhập thành công !');
          });
        } else {
          this.commonService.toastrWarning(res.message);
        }
      }),
      catchError(AuthService.handleError)
    );
  }

  private loadSavedAuthData(): void {
    const tokenString = this.getSavedAuthData();
    this.setAuthData(tokenString);
  }

  private getSavedAuthData() {
    if (!localStorage) return null;
    return this.tokenService.getToken();
  }

  private setAuthData(tokenString: string): void {
    this.token = tokenString;
  }

  async refreshToken(tag: string = null): Promise<any> {
    const body = new TokenForRefresh({
      tag: tag
    });

    await this.tokenService.getUserInfo().subscribe(result => {
      body.refreshToken = result.RefreshToken;
    });
    return this.appQthtService.appAuthApiAuthRefreshToken(body)
      .pipe(
        tap(res => {
          if (res.code === SUCCESS_RESPONSE) {
            this.token = res.data.jwtToken;
            const usInfor: IUserDataToken = {
              Id: res.data.id,
              FullName: res.data.fullName,
              UnitCode: res.data.unitCode,
              UnitName: res.data.unitName,
              RefreshToken: res.data.refreshToken,
            };
            this.tokenService.saveToken(res.data.jwtToken);
            this.tokenService.setUserInfo(usInfor, this.jwtHelper.getTokenExpirationDate());
          }
        }),
        catchError(AuthService.handleError)
      );
  }

  logout(): void {
    this.token = null;
    this.tokenService.removeToken();
    // this.tokenService.removeRefreshToken();
    this.tokenService.removeCookie();
  }

  register(data: any): Observable<any> {
    // return this.http.post<any>(API_URL + 'oauth/signup', data)
    //   .pipe(
    //     tap(_ => AuthService.log('register')),
    //     catchError(AuthService.handleError)
    //   );
    return null;
  }

  secured(): Observable<any> {
    // return this.http.get<any>(API_URL + 'secret')
    //   .pipe(catchError(AuthService.handleError));
    return null;
  }

  checkTokenExpired(): boolean {
    if (this.jwtHelper.isTokenExpired()) {
      this.router.navigate(['/login']).then(_ => false);
      return true;
    }
    return false;
  }

  routingMach(routing: string): boolean {
    let checkRouting = false;
    // this.menuService.getRoutingSaveCheckRole().subscribe(result => {
    //   if (result.length === 0) {
    //     this.menuService.getMenuByUserLogin().subscribe(menuLogin => {
    //       if (menuLogin.code === SUCCESS_RESPONSE) {
    //        this.menuService.setRoutingSaveCheckRole(menuLogin.data);
    //        if (menuLogin.data.findIndex(c => c.state === routing) !== -1) {
    //          checkRouting = true;
    //        }
    //       }
    //     });
    //   } else {
    //     if (result.findIndex(c => c.state === routing) !== -1) {
    //       checkRouting = true;
    //     }
    //   }
    // });
    return checkRouting;
  }
}
