import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AboutComponent } from "./components/pages/about/about.component";
import { ServicesComponent } from "./components/pages/services/services.component";
import { ServiceDetailsComponent } from "./components/pages/service-details/service-details.component";
import { ProjectsComponent } from "./components/pages/projects/projects.component";
import { ProjectDetailsComponent } from "./components/pages/project-details/project-details.component";
import { BlogComponent } from "./components/pages/blog/blog.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { FeaturesComponent } from "./components/pages/features/features.component";
import { TeamComponent } from "./components/pages/team/team.component";
import { PricingComponent } from "./components/pages/pricing/pricing.component";
import { PartnerComponent } from "./components/pages/partner/partner.component";
import { NotFoundComponent } from "./components/pages/not-found/not-found.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { HomeDemoSixComponent } from "./components/pages/home-demo-six/home-demo-six.component";
import { AboutStyleTwoComponent } from "./components/pages/about-style-two/about-style-two.component";
import { TeamStyleTwoComponent } from "./components/pages/team-style-two/team-style-two.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { RegisterComponent } from "./components/pages/register/register.component";
import { ServicesStyleTwoComponent } from "./components/pages/services-style-two/services-style-two.component";
import { ServicesStyleThreeComponent } from "./components/pages/services-style-three/services-style-three.component";
import { ProjectsStyleTwoComponent } from "./components/pages/projects-style-two/projects-style-two.component";
import { ProjectsStyleThreeComponent } from "./components/pages/projects-style-three/projects-style-three.component";
import { ProjectsStyleFourComponent } from "./components/pages/projects-style-four/projects-style-four.component";
import { BlogRightSidebarComponent } from "./components/pages/blog-right-sidebar/blog-right-sidebar.component";

const routes: Routes = [
    { path: "", redirectTo: "SitePages/default.aspx", pathMatch: "full" },
    { path: "SitePages/default.aspx", component: HomeDemoSixComponent },
    { path: "about", component: AboutComponent },
    { path: "about-2", component: AboutStyleTwoComponent },
    { path: "hdsearch", component: ServicesComponent },
    { path: "hdaddacc", component: ServicesStyleTwoComponent },
    { path: "services-3", component: ServicesStyleThreeComponent },
    { path: "service-details", component: ServiceDetailsComponent },
    { path: "projects", component: ProjectsComponent },
    { path: "projects-2", component: ProjectsStyleTwoComponent },
    { path: "projects-3", component: ProjectsStyleThreeComponent },
    { path: "projects-4", component: ProjectsStyleFourComponent },
    { path: "project-details", component: ProjectDetailsComponent },
    { path: "blog", component: BlogComponent },
    { path: "blog-2", component: BlogRightSidebarComponent },
    { path: "blog-details", component: BlogDetailsComponent },
    { path: "features", component: FeaturesComponent },
    { path: "blog", component: TeamComponent },
    { path: "team-2", component: TeamStyleTwoComponent },
    { path: "pricing", component: PricingComponent },
    { path: "partner", component: PartnerComponent },
    { path: "faq", component: FaqComponent },
    { path: "login", component: LoginComponent },
    { path: "register", component: RegisterComponent },
    { path: "contact", component: ContactComponent },
    // Here add new pages component

    { path: "**", component: NotFoundComponent }, // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
