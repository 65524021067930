import { ApiResponse, AppQthtApiService, ChangePasswordModel } from './../../../common/app-qtht.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ChangePasswordService {
    constructor (
        private api: AppQthtApiService
    ) {}

    changePassword(body: ChangePasswordModel | undefined): Observable<ApiResponse> {
        return this.api.appQthtApiSysuserChangepassword(body);
    }
}
